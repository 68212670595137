var tan30 = Math.sqrt(1 / 3),
  tan30_2 = tan30 * 2;

export default {
  draw: function(context, size) {
    var y = Math.sqrt(size / tan30_2),
      x = y * tan30;
    context.moveTo(0, -y);
    context.lineTo(x, 0);
    context.lineTo(0, y);
    context.lineTo(-x, 0);
    context.closePath();
  },
};
