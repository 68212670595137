import interval from './interval.js';

var year = interval(
  function(date) {
    date.setMonth(0, 1);
    date.setHours(0, 0, 0, 0);
  },
  function(date, step) {
    date.setFullYear(date.getFullYear() + step);
  },
  function(start, end) {
    return end.getFullYear() - start.getFullYear();
  },
  function(date) {
    return date.getFullYear();
  }
);

// An optimized implementation for this simple case.
year.every = function(k) {
  return !isFinite((k = Math.floor(k))) || !(k > 0)
    ? null
    : interval(
        function(date) {
          date.setFullYear(Math.floor(date.getFullYear() / k) * k);
          date.setMonth(0, 1);
          date.setHours(0, 0, 0, 0);
        },
        function(date, step) {
          date.setFullYear(date.getFullYear() + step * k);
        }
      );
};

export default year;
export var years = year.range;
