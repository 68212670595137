'use strict';

module.exports = function getDefinitions(node) {
  var defs =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (node.children || []).reduce(function(definitions, child) {
    if (child.type === 'definition') {
      definitions[child.identifier] = {
        href: child.url,
        title: child.title,
      };
    }

    return getDefinitions(child, definitions);
  }, defs);
};
