module.exports = function(obj) {
  var onData,
    onEnd,
    events = [];

  // buffer data
  obj.on(
    'data',
    (onData = function(data, encoding) {
      events.push(['data', data, encoding]);
    })
  );

  // buffer end
  obj.on(
    'end',
    (onEnd = function(data, encoding) {
      events.push(['end', data, encoding]);
    })
  );

  return {
    end: function() {
      obj.removeListener('data', onData);
      obj.removeListener('end', onEnd);
    },
    resume: function() {
      this.end();
      for (var i = 0, len = events.length; i < len; ++i) {
        obj.emit.apply(obj, events[i]);
      }
    },
  };
};
