import { range as sequence } from 'd3-array';
import ordinal from './ordinal';

export default function band() {
  var scale = ordinal().unknown(undefined),
    domain = scale.domain,
    ordinalRange = scale.range,
    range = [0, 1],
    step,
    bandwidth,
    round = false,
    paddingInner = 0,
    paddingOuter = 0,
    align = 0.5;

  delete scale.unknown;

  function rescale() {
    var n = domain().length,
      reverse = range[1] < range[0],
      start = range[reverse - 0],
      stop = range[1 - reverse];
    step = (stop - start) / Math.max(1, n - paddingInner + paddingOuter * 2);
    if (round) step = Math.floor(step);
    start += (stop - start - step * (n - paddingInner)) * align;
    bandwidth = step * (1 - paddingInner);
    if (round) (start = Math.round(start)), (bandwidth = Math.round(bandwidth));
    var values = sequence(n).map(function(i) {
      return start + step * i;
    });
    return ordinalRange(reverse ? values.reverse() : values);
  }

  scale.domain = function(_) {
    return arguments.length ? (domain(_), rescale()) : domain();
  };

  scale.range = function(_) {
    return arguments.length
      ? ((range = [+_[0], +_[1]]), rescale())
      : range.slice();
  };

  scale.rangeRound = function(_) {
    return (range = [+_[0], +_[1]]), (round = true), rescale();
  };

  scale.bandwidth = function() {
    return bandwidth;
  };

  scale.step = function() {
    return step;
  };

  scale.round = function(_) {
    return arguments.length ? ((round = !!_), rescale()) : round;
  };

  scale.padding = function(_) {
    return arguments.length
      ? ((paddingInner = paddingOuter = Math.max(0, Math.min(1, _))), rescale())
      : paddingInner;
  };

  scale.paddingInner = function(_) {
    return arguments.length
      ? ((paddingInner = Math.max(0, Math.min(1, _))), rescale())
      : paddingInner;
  };

  scale.paddingOuter = function(_) {
    return arguments.length
      ? ((paddingOuter = Math.max(0, Math.min(1, _))), rescale())
      : paddingOuter;
  };

  scale.align = function(_) {
    return arguments.length
      ? ((align = Math.max(0, Math.min(1, _))), rescale())
      : align;
  };

  scale.copy = function() {
    return band()
      .domain(domain())
      .range(range)
      .round(round)
      .paddingInner(paddingInner)
      .paddingOuter(paddingOuter)
      .align(align);
  };

  return rescale();
}

function pointish(scale) {
  var copy = scale.copy;

  scale.padding = scale.paddingOuter;
  delete scale.paddingInner;
  delete scale.paddingOuter;

  scale.copy = function() {
    return pointish(copy());
  };

  return scale;
}

export function point() {
  return pointish(band().paddingInner(1));
}
