import constant from './constant';
import { linearish } from './linear';
import { default as continuous, copy } from './continuous';

function raise(x, exponent) {
  return x < 0 ? -Math.pow(-x, exponent) : Math.pow(x, exponent);
}

export default function pow() {
  var exponent = 1,
    scale = continuous(deinterpolate, reinterpolate),
    domain = scale.domain;

  function deinterpolate(a, b) {
    return (b = raise(b, exponent) - (a = raise(a, exponent)))
      ? function(x) {
          return (raise(x, exponent) - a) / b;
        }
      : constant(b);
  }

  function reinterpolate(a, b) {
    b = raise(b, exponent) - (a = raise(a, exponent));
    return function(t) {
      return raise(a + b * t, 1 / exponent);
    };
  }

  scale.exponent = function(_) {
    return arguments.length ? ((exponent = +_), domain(domain())) : exponent;
  };

  scale.copy = function() {
    return copy(scale, pow().exponent(exponent));
  };

  return linearish(scale);
}

export function sqrt() {
  return pow().exponent(0.5);
}
