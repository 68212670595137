import { bisect } from 'd3-array';
import { slice } from './array';
import { linearish } from './linear';

export default function quantize() {
  var x0 = 0,
    x1 = 1,
    n = 1,
    domain = [0.5],
    range = [0, 1];

  function scale(x) {
    if (x <= x) return range[bisect(domain, x, 0, n)];
  }

  function rescale() {
    var i = -1;
    domain = new Array(n);
    while (++i < n) domain[i] = ((i + 1) * x1 - (i - n) * x0) / (n + 1);
    return scale;
  }

  scale.domain = function(_) {
    return arguments.length
      ? ((x0 = +_[0]), (x1 = +_[1]), rescale())
      : [x0, x1];
  };

  scale.range = function(_) {
    return arguments.length
      ? ((n = (range = slice.call(_)).length - 1), rescale())
      : range.slice();
  };

  scale.invertExtent = function(y) {
    var i = range.indexOf(y);
    return i < 0
      ? [NaN, NaN]
      : i < 1
      ? [x0, domain[0]]
      : i >= n
      ? [domain[n - 1], x1]
      : [domain[i - 1], domain[i]];
  };

  scale.copy = function() {
    return quantize()
      .domain([x0, x1])
      .range(range);
  };

  return linearish(scale);
}
