import interval from './interval.js';

var millisecond = interval(
  function() {
    // noop
  },
  function(date, step) {
    date.setTime(+date + step);
  },
  function(start, end) {
    return end - start;
  }
);

// An optimized implementation for this simple case.
millisecond.every = function(k) {
  k = Math.floor(k);
  if (!isFinite(k) || !(k > 0)) return null;
  if (!(k > 1)) return millisecond;
  return interval(
    function(date) {
      date.setTime(Math.floor(date / k) * k);
    },
    function(date, step) {
      date.setTime(+date + step * k);
    },
    function(start, end) {
      return (end - start) / k;
    }
  );
};

export default millisecond;
export var milliseconds = millisecond.range;
