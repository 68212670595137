var t0 = new Date(),
  t1 = new Date();

export default function newInterval(floori, offseti, count, field) {
  function interval(date) {
    return (
      floori((date = arguments.length === 0 ? new Date() : new Date(+date))),
      date
    );
  }

  interval.floor = function(date) {
    return floori((date = new Date(+date))), date;
  };

  interval.ceil = function(date) {
    return (
      floori((date = new Date(date - 1))), offseti(date, 1), floori(date), date
    );
  };

  interval.round = function(date) {
    var d0 = interval(date),
      d1 = interval.ceil(date);
    return date - d0 < d1 - date ? d0 : d1;
  };

  interval.offset = function(date, step) {
    return (
      offseti((date = new Date(+date)), step == null ? 1 : Math.floor(step)),
      date
    );
  };

  interval.range = function(start, stop, step) {
    var range = [],
      previous;
    start = interval.ceil(start);
    step = step == null ? 1 : Math.floor(step);
    if (!(start < stop) || !(step > 0)) return range; // also handles Invalid Date
    do
      range.push((previous = new Date(+start))),
        offseti(start, step),
        floori(start);
    while (previous < start && start < stop);
    return range;
  };

  interval.filter = function(test) {
    return newInterval(
      function(date) {
        if (date >= date)
          while ((floori(date), !test(date))) date.setTime(date - 1);
      },
      function(date, step) {
        if (date >= date) {
          if (step < 0)
            while (++step <= 0) {
              while ((offseti(date, -1), !test(date))) {} // eslint-disable-line no-empty
            }
          else
            while (--step >= 0) {
              while ((offseti(date, +1), !test(date))) {} // eslint-disable-line no-empty
            }
        }
      }
    );
  };

  if (count) {
    interval.count = function(start, end) {
      t0.setTime(+start), t1.setTime(+end);
      floori(t0), floori(t1);
      return Math.floor(count(t0, t1));
    };

    interval.every = function(step) {
      step = Math.floor(step);
      return !isFinite(step) || !(step > 0)
        ? null
        : !(step > 1)
        ? interval
        : interval.filter(
            field
              ? function(d) {
                  return field(d) % step === 0;
                }
              : function(d) {
                  return interval.count(0, d) % step === 0;
                }
          );
    };
  }

  return interval;
}
