import formatDecimal from './formatDecimal.js';
import formatPrefixAuto from './formatPrefixAuto.js';
import formatRounded from './formatRounded.js';

export default {
  '%': function(x, p) {
    return (x * 100).toFixed(p);
  },
  b: function(x) {
    return Math.round(x).toString(2);
  },
  c: function(x) {
    return x + '';
  },
  d: formatDecimal,
  e: function(x, p) {
    return x.toExponential(p);
  },
  f: function(x, p) {
    return x.toFixed(p);
  },
  g: function(x, p) {
    return x.toPrecision(p);
  },
  o: function(x) {
    return Math.round(x).toString(8);
  },
  p: function(x, p) {
    return formatRounded(x * 100, p);
  },
  r: formatRounded,
  s: formatPrefixAuto,
  X: function(x) {
    return Math.round(x)
      .toString(16)
      .toUpperCase();
  },
  x: function(x) {
    return Math.round(x).toString(16);
  },
};
