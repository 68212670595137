var ClientRequest = require('./lib/request');
var response = require('./lib/response');
var extend = require('xtend');
var statusCodes = require('builtin-status-codes');
var url = require('url');

var http = exports;

http.request = function(opts, cb) {
  if (typeof opts === 'string') opts = url.parse(opts);
  else opts = extend(opts);

  // Normally, the page is loaded from http or https, so not specifying a protocol
  // will result in a (valid) protocol-relative url. However, this won't work if
  // the protocol is something else, like 'file:'
  var defaultProtocol =
    global.location.protocol.search(/^https?:$/) === -1 ? 'http:' : '';

  var protocol = opts.protocol || defaultProtocol;
  var host = opts.hostname || opts.host;
  var port = opts.port;
  var path = opts.path || '/';

  // Necessary for IPv6 addresses
  if (host && host.indexOf(':') !== -1) host = '[' + host + ']';

  // This may be a relative url. The browser should always be able to interpret it correctly.
  opts.url =
    (host ? protocol + '//' + host : '') + (port ? ':' + port : '') + path;
  opts.method = (opts.method || 'GET').toUpperCase();
  opts.headers = opts.headers || {};

  // Also valid opts.auth, opts.mode

  var req = new ClientRequest(opts);
  if (cb) req.on('response', cb);
  return req;
};

http.get = function get(opts, cb) {
  var req = http.request(opts, cb);
  req.end();
  return req;
};

http.ClientRequest = ClientRequest;
http.IncomingMessage = response.IncomingMessage;

http.Agent = function() {};
http.Agent.defaultMaxSockets = 4;

http.globalAgent = new http.Agent();

http.STATUS_CODES = statusCodes;

http.METHODS = [
  'CHECKOUT',
  'CONNECT',
  'COPY',
  'DELETE',
  'GET',
  'HEAD',
  'LOCK',
  'M-SEARCH',
  'MERGE',
  'MKACTIVITY',
  'MKCOL',
  'MOVE',
  'NOTIFY',
  'OPTIONS',
  'PATCH',
  'POST',
  'PROPFIND',
  'PROPPATCH',
  'PURGE',
  'PUT',
  'REPORT',
  'SEARCH',
  'SUBSCRIBE',
  'TRACE',
  'UNLOCK',
  'UNSUBSCRIBE',
];
