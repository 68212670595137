export default function(series, order) {
  if (!((n = series.length) > 1)) return;
  for (
    var i, j = 0, d, dy, yp, yn, n, m = series[order[0]].length;
    j < m;
    ++j
  ) {
    for (yp = yn = 0, i = 0; i < n; ++i) {
      if ((dy = (d = series[order[i]][j])[1] - d[0]) >= 0) {
        (d[0] = yp), (d[1] = yp += dy);
      } else if (dy < 0) {
        (d[1] = yn), (d[0] = yn += dy);
      } else {
        d[0] = yp;
      }
    }
  }
}
