var baseCreate = require('./_baseCreate'),
  getPrototype = require('./_getPrototype'),
  isPrototype = require('./_isPrototype');

/**
 * Initializes an object clone.
 *
 * @private
 * @param {Object} object The object to clone.
 * @returns {Object} Returns the initialized clone.
 */
function initCloneObject(object) {
  return typeof object.constructor == 'function' && !isPrototype(object)
    ? baseCreate(getPrototype(object))
    : {};
}

module.exports = initCloneObject;
