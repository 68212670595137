import { linearish } from './linear';

export default function sequential(interpolator) {
  var x0 = 0,
    x1 = 1,
    k10 = 1,
    clamp = false;

  function scale(x) {
    var t = (x - x0) * k10;
    return interpolator(clamp ? Math.max(0, Math.min(1, t)) : t);
  }

  scale.domain = function(_) {
    return arguments.length
      ? ((x0 = +_[0]),
        (x1 = +_[1]),
        (k10 = x0 === x1 ? 0 : 1 / (x1 - x0)),
        scale)
      : [x0, x1];
  };

  scale.clamp = function(_) {
    return arguments.length ? ((clamp = !!_), scale) : clamp;
  };

  scale.interpolator = function(_) {
    return arguments.length ? ((interpolator = _), scale) : interpolator;
  };

  scale.copy = function() {
    return sequential(interpolator)
      .domain([x0, x1])
      .clamp(clamp);
  };

  return linearish(scale);
}
