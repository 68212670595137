import { bisect } from 'd3-array';
import { slice } from './array';

export default function threshold() {
  var domain = [0.5],
    range = [0, 1],
    n = 1;

  function scale(x) {
    if (x <= x) return range[bisect(domain, x, 0, n)];
  }

  scale.domain = function(_) {
    return arguments.length
      ? ((domain = slice.call(_)),
        (n = Math.min(domain.length, range.length - 1)),
        scale)
      : domain.slice();
  };

  scale.range = function(_) {
    return arguments.length
      ? ((range = slice.call(_)),
        (n = Math.min(domain.length, range.length - 1)),
        scale)
      : range.slice();
  };

  scale.invertExtent = function(y) {
    var i = range.indexOf(y);
    return [domain[i - 1], domain[i]];
  };

  scale.copy = function() {
    return threshold()
      .domain(domain)
      .range(range);
  };

  return scale;
}
