var visitWithParents = require('unist-util-visit-parents');

function addListMetadata() {
  return function(ast) {
    visitWithParents(ast, 'list', function(listNode, parents) {
      var depth = 0,
        i,
        n;
      for (i = 0, n = parents.length; i < n; i++) {
        if (parents[i].type === 'list') depth += 1;
      }
      for (i = 0, n = listNode.children.length; i < n; i++) {
        var child = listNode.children[i];
        child.index = i;
        child.ordered = listNode.ordered;
      }
      listNode.depth = depth;
    });
    return ast;
  };
}

module.exports = addListMetadata;
