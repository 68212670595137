import noop from '../noop';
import { point } from './basis';

function BasisClosed(context) {
  this._context = context;
}

BasisClosed.prototype = {
  areaStart: noop,
  areaEnd: noop,
  lineStart: function() {
    this._x0 = this._x1 = this._x2 = this._x3 = this._x4 = this._y0 = this._y1 = this._y2 = this._y3 = this._y4 = NaN;
    this._point = 0;
  },
  lineEnd: function() {
    switch (this._point) {
      case 1: {
        this._context.moveTo(this._x2, this._y2);
        this._context.closePath();
        break;
      }
      case 2: {
        this._context.moveTo(
          (this._x2 + 2 * this._x3) / 3,
          (this._y2 + 2 * this._y3) / 3
        );
        this._context.lineTo(
          (this._x3 + 2 * this._x2) / 3,
          (this._y3 + 2 * this._y2) / 3
        );
        this._context.closePath();
        break;
      }
      case 3: {
        this.point(this._x2, this._y2);
        this.point(this._x3, this._y3);
        this.point(this._x4, this._y4);
        break;
      }
    }
  },
  point: function(x, y) {
    (x = +x), (y = +y);
    switch (this._point) {
      case 0:
        this._point = 1;
        (this._x2 = x), (this._y2 = y);
        break;
      case 1:
        this._point = 2;
        (this._x3 = x), (this._y3 = y);
        break;
      case 2:
        this._point = 3;
        (this._x4 = x), (this._y4 = y);
        this._context.moveTo(
          (this._x0 + 4 * this._x1 + x) / 6,
          (this._y0 + 4 * this._y1 + y) / 6
        );
        break;
      default:
        point(this, x, y);
        break;
    }
    (this._x0 = this._x1), (this._x1 = x);
    (this._y0 = this._y1), (this._y1 = y);
  },
};

export default function(context) {
  return new BasisClosed(context);
}
