import { path } from 'd3-path';
import constant from './constant';
import curveLinear from './curve/linear';
import line from './line';
import { x as pointX, y as pointY } from './point';

export default function() {
  var x0 = pointX,
    x1 = null,
    y0 = constant(0),
    y1 = pointY,
    defined = constant(true),
    context = null,
    curve = curveLinear,
    output = null;

  function area(data) {
    var i,
      j,
      k,
      n = data.length,
      d,
      defined0 = false,
      buffer,
      x0z = new Array(n),
      y0z = new Array(n);

    if (context == null) output = curve((buffer = path()));

    for (i = 0; i <= n; ++i) {
      if (!(i < n && defined((d = data[i]), i, data)) === defined0) {
        if ((defined0 = !defined0)) {
          j = i;
          output.areaStart();
          output.lineStart();
        } else {
          output.lineEnd();
          output.lineStart();
          for (k = i - 1; k >= j; --k) {
            output.point(x0z[k], y0z[k]);
          }
          output.lineEnd();
          output.areaEnd();
        }
      }
      if (defined0) {
        (x0z[i] = +x0(d, i, data)), (y0z[i] = +y0(d, i, data));
        output.point(
          x1 ? +x1(d, i, data) : x0z[i],
          y1 ? +y1(d, i, data) : y0z[i]
        );
      }
    }

    if (buffer) return (output = null), buffer + '' || null;
  }

  function arealine() {
    return line()
      .defined(defined)
      .curve(curve)
      .context(context);
  }

  area.x = function(_) {
    return arguments.length
      ? ((x0 = typeof _ === 'function' ? _ : constant(+_)), (x1 = null), area)
      : x0;
  };

  area.x0 = function(_) {
    return arguments.length
      ? ((x0 = typeof _ === 'function' ? _ : constant(+_)), area)
      : x0;
  };

  area.x1 = function(_) {
    return arguments.length
      ? ((x1 = _ == null ? null : typeof _ === 'function' ? _ : constant(+_)),
        area)
      : x1;
  };

  area.y = function(_) {
    return arguments.length
      ? ((y0 = typeof _ === 'function' ? _ : constant(+_)), (y1 = null), area)
      : y0;
  };

  area.y0 = function(_) {
    return arguments.length
      ? ((y0 = typeof _ === 'function' ? _ : constant(+_)), area)
      : y0;
  };

  area.y1 = function(_) {
    return arguments.length
      ? ((y1 = _ == null ? null : typeof _ === 'function' ? _ : constant(+_)),
        area)
      : y1;
  };

  area.lineX0 = area.lineY0 = function() {
    return arealine()
      .x(x0)
      .y(y0);
  };

  area.lineY1 = function() {
    return arealine()
      .x(x0)
      .y(y1);
  };

  area.lineX1 = function() {
    return arealine()
      .x(x1)
      .y(y0);
  };

  area.defined = function(_) {
    return arguments.length
      ? ((defined = typeof _ === 'function' ? _ : constant(!!_)), area)
      : defined;
  };

  area.curve = function(_) {
    return arguments.length
      ? ((curve = _), context != null && (output = curve(context)), area)
      : curve;
  };

  area.context = function(_) {
    return arguments.length
      ? (_ == null
          ? (context = output = null)
          : (output = curve((context = _))),
        area)
      : context;
  };

  return area;
}
