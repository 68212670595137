'use strict';

exports.__esModule = true;
exports.default = hasClass;

function hasClass(element, className) {
  if (element.classList)
    return !!className && element.classList.contains(className);
  else
    return (
      (' ' + (element.className.baseVal || element.className) + ' ').indexOf(
        ' ' + className + ' '
      ) !== -1
    );
}

module.exports = exports['default'];
