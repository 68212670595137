// [[fill]align][sign][symbol][0][width][,][.precision][~][type]
var re = /^(?:(.)?([<>=^]))?([+\-( ])?([$#])?(0)?(\d+)?(,)?(\.\d+)?(~)?([a-z%])?$/i;

export default function formatSpecifier(specifier) {
  if (!(match = re.exec(specifier)))
    throw new Error('invalid format: ' + specifier);
  var match;
  return new FormatSpecifier({
    fill: match[1],
    align: match[2],
    sign: match[3],
    symbol: match[4],
    zero: match[5],
    width: match[6],
    comma: match[7],
    precision: match[8] && match[8].slice(1),
    trim: match[9],
    type: match[10],
  });
}

formatSpecifier.prototype = FormatSpecifier.prototype; // instanceof

export function FormatSpecifier(specifier) {
  this.fill = specifier.fill === undefined ? ' ' : specifier.fill + '';
  this.align = specifier.align === undefined ? '>' : specifier.align + '';
  this.sign = specifier.sign === undefined ? '-' : specifier.sign + '';
  this.symbol = specifier.symbol === undefined ? '' : specifier.symbol + '';
  this.zero = !!specifier.zero;
  this.width = specifier.width === undefined ? undefined : +specifier.width;
  this.comma = !!specifier.comma;
  this.precision =
    specifier.precision === undefined ? undefined : +specifier.precision;
  this.trim = !!specifier.trim;
  this.type = specifier.type === undefined ? '' : specifier.type + '';
}

FormatSpecifier.prototype.toString = function() {
  return (
    this.fill +
    this.align +
    this.sign +
    this.symbol +
    (this.zero ? '0' : '') +
    (this.width === undefined ? '' : Math.max(1, this.width | 0)) +
    (this.comma ? ',' : '') +
    (this.precision === undefined
      ? ''
      : '.' + Math.max(0, this.precision | 0)) +
    (this.trim ? '~' : '') +
    this.type
  );
};
