'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});

var _ResizeDetector = require('./components/ResizeDetector');

var _ResizeDetector2 = _interopRequireDefault(_ResizeDetector);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = _ResizeDetector2.default;
