import { path } from 'd3-path';
import constant from './constant';
import curveLinear from './curve/linear';
import { x as pointX, y as pointY } from './point';

export default function() {
  var x = pointX,
    y = pointY,
    defined = constant(true),
    context = null,
    curve = curveLinear,
    output = null;

  function line(data) {
    var i,
      n = data.length,
      d,
      defined0 = false,
      buffer;

    if (context == null) output = curve((buffer = path()));

    for (i = 0; i <= n; ++i) {
      if (!(i < n && defined((d = data[i]), i, data)) === defined0) {
        if ((defined0 = !defined0)) output.lineStart();
        else output.lineEnd();
      }
      if (defined0) output.point(+x(d, i, data), +y(d, i, data));
    }

    if (buffer) return (output = null), buffer + '' || null;
  }

  line.x = function(_) {
    return arguments.length
      ? ((x = typeof _ === 'function' ? _ : constant(+_)), line)
      : x;
  };

  line.y = function(_) {
    return arguments.length
      ? ((y = typeof _ === 'function' ? _ : constant(+_)), line)
      : y;
  };

  line.defined = function(_) {
    return arguments.length
      ? ((defined = typeof _ === 'function' ? _ : constant(!!_)), line)
      : defined;
  };

  line.curve = function(_) {
    return arguments.length
      ? ((curve = _), context != null && (output = curve(context)), line)
      : curve;
  };

  line.context = function(_) {
    return arguments.length
      ? (_ == null
          ? (context = output = null)
          : (output = curve((context = _))),
        line)
      : context;
  };

  return line;
}
