import formatLocale from './locale.js';

var locale;
export var format;
export var formatPrefix;

defaultLocale({
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['$', ''],
  minus: '-',
});

export default function defaultLocale(definition) {
  locale = formatLocale(definition);
  format = locale.format;
  formatPrefix = locale.formatPrefix;
  return locale;
}
