export default function(grouping, thousands) {
  return function(value, width) {
    var i = value.length,
      t = [],
      j = 0,
      g = grouping[0],
      length = 0;

    while (i > 0 && g > 0) {
      if (length + g + 1 > width) g = Math.max(1, width - length);
      t.push(value.substring((i -= g), i + g));
      if ((length += g + 1) > width) break;
      g = grouping[(j = (j + 1) % grouping.length)];
    }

    return t.reverse().join(thousands);
  };
}
