import constant from './constant';
import descending from './descending';
import identity from './identity';
import { tau } from './math';

export default function() {
  var value = identity,
    sortValues = descending,
    sort = null,
    startAngle = constant(0),
    endAngle = constant(tau),
    padAngle = constant(0);

  function pie(data) {
    var i,
      n = data.length,
      j,
      k,
      sum = 0,
      index = new Array(n),
      arcs = new Array(n),
      a0 = +startAngle.apply(this, arguments),
      da = Math.min(tau, Math.max(-tau, endAngle.apply(this, arguments) - a0)),
      a1,
      p = Math.min(Math.abs(da) / n, padAngle.apply(this, arguments)),
      pa = p * (da < 0 ? -1 : 1),
      v;

    for (i = 0; i < n; ++i) {
      if ((v = arcs[(index[i] = i)] = +value(data[i], i, data)) > 0) {
        sum += v;
      }
    }

    // Optionally sort the arcs by previously-computed values or by data.
    if (sortValues != null)
      index.sort(function(i, j) {
        return sortValues(arcs[i], arcs[j]);
      });
    else if (sort != null)
      index.sort(function(i, j) {
        return sort(data[i], data[j]);
      });

    // Compute the arcs! They are stored in the original data's order.
    for (i = 0, k = sum ? (da - n * pa) / sum : 0; i < n; ++i, a0 = a1) {
      (j = index[i]),
        (v = arcs[j]),
        (a1 = a0 + (v > 0 ? v * k : 0) + pa),
        (arcs[j] = {
          data: data[j],
          index: i,
          value: v,
          startAngle: a0,
          endAngle: a1,
          padAngle: p,
        });
    }

    return arcs;
  }

  pie.value = function(_) {
    return arguments.length
      ? ((value = typeof _ === 'function' ? _ : constant(+_)), pie)
      : value;
  };

  pie.sortValues = function(_) {
    return arguments.length
      ? ((sortValues = _), (sort = null), pie)
      : sortValues;
  };

  pie.sort = function(_) {
    return arguments.length ? ((sort = _), (sortValues = null), pie) : sort;
  };

  pie.startAngle = function(_) {
    return arguments.length
      ? ((startAngle = typeof _ === 'function' ? _ : constant(+_)), pie)
      : startAngle;
  };

  pie.endAngle = function(_) {
    return arguments.length
      ? ((endAngle = typeof _ === 'function' ? _ : constant(+_)), pie)
      : endAngle;
  };

  pie.padAngle = function(_) {
    return arguments.length
      ? ((padAngle = typeof _ === 'function' ? _ : constant(+_)), pie)
      : padAngle;
  };

  return pie;
}
