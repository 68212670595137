import { ascending, bisect, quantile as threshold } from 'd3-array';
import { slice } from './array';

export default function quantile() {
  var domain = [],
    range = [],
    thresholds = [];

  function rescale() {
    var i = 0,
      n = Math.max(1, range.length);
    thresholds = new Array(n - 1);
    while (++i < n) thresholds[i - 1] = threshold(domain, i / n);
    return scale;
  }

  function scale(x) {
    if (!isNaN((x = +x))) return range[bisect(thresholds, x)];
  }

  scale.invertExtent = function(y) {
    var i = range.indexOf(y);
    return i < 0
      ? [NaN, NaN]
      : [
          i > 0 ? thresholds[i - 1] : domain[0],
          i < thresholds.length ? thresholds[i] : domain[domain.length - 1],
        ];
  };

  scale.domain = function(_) {
    if (!arguments.length) return domain.slice();
    domain = [];
    for (var i = 0, n = _.length, d; i < n; ++i)
      if (((d = _[i]), d != null && !isNaN((d = +d)))) domain.push(d);
    domain.sort(ascending);
    return rescale();
  };

  scale.range = function(_) {
    return arguments.length
      ? ((range = slice.call(_)), rescale())
      : range.slice();
  };

  scale.quantiles = function() {
    return thresholds.slice();
  };

  scale.copy = function() {
    return quantile()
      .domain(domain)
      .range(range);
  };

  return scale;
}
