import number from '../number.js';
import { parseCss, parseSvg } from './parse.js';

function interpolateTransform(parse, pxComma, pxParen, degParen) {
  function pop(s) {
    return s.length ? s.pop() + ' ' : '';
  }

  function translate(xa, ya, xb, yb, s, q) {
    if (xa !== xb || ya !== yb) {
      var i = s.push('translate(', null, pxComma, null, pxParen);
      q.push({ i: i - 4, x: number(xa, xb) }, { i: i - 2, x: number(ya, yb) });
    } else if (xb || yb) {
      s.push('translate(' + xb + pxComma + yb + pxParen);
    }
  }

  function rotate(a, b, s, q) {
    if (a !== b) {
      if (a - b > 180) b += 360;
      else if (b - a > 180) a += 360; // shortest path
      q.push({
        i: s.push(pop(s) + 'rotate(', null, degParen) - 2,
        x: number(a, b),
      });
    } else if (b) {
      s.push(pop(s) + 'rotate(' + b + degParen);
    }
  }

  function skewX(a, b, s, q) {
    if (a !== b) {
      q.push({
        i: s.push(pop(s) + 'skewX(', null, degParen) - 2,
        x: number(a, b),
      });
    } else if (b) {
      s.push(pop(s) + 'skewX(' + b + degParen);
    }
  }

  function scale(xa, ya, xb, yb, s, q) {
    if (xa !== xb || ya !== yb) {
      var i = s.push(pop(s) + 'scale(', null, ',', null, ')');
      q.push({ i: i - 4, x: number(xa, xb) }, { i: i - 2, x: number(ya, yb) });
    } else if (xb !== 1 || yb !== 1) {
      s.push(pop(s) + 'scale(' + xb + ',' + yb + ')');
    }
  }

  return function(a, b) {
    var s = [], // string constants and placeholders
      q = []; // number interpolators
    (a = parse(a)), (b = parse(b));
    translate(a.translateX, a.translateY, b.translateX, b.translateY, s, q);
    rotate(a.rotate, b.rotate, s, q);
    skewX(a.skewX, b.skewX, s, q);
    scale(a.scaleX, a.scaleY, b.scaleX, b.scaleY, s, q);
    a = b = null; // gc
    return function(t) {
      var i = -1,
        n = q.length,
        o;
      while (++i < n) s[(o = q[i]).i] = o.x(t);
      return s.join('');
    };
  };
}

export var interpolateTransformCss = interpolateTransform(
  parseCss,
  'px, ',
  'px)',
  'deg)'
);
export var interpolateTransformSvg = interpolateTransform(
  parseSvg,
  ', ',
  ')',
  ')'
);
