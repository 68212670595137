import exponent from './exponent.js';
import formatGroup from './formatGroup.js';
import formatNumerals from './formatNumerals.js';
import formatSpecifier from './formatSpecifier.js';
import formatTrim from './formatTrim.js';
import formatTypes from './formatTypes.js';
import { prefixExponent } from './formatPrefixAuto.js';
import identity from './identity.js';

var map = Array.prototype.map,
  prefixes = [
    'y',
    'z',
    'a',
    'f',
    'p',
    'n',
    'µ',
    'm',
    '',
    'k',
    'M',
    'G',
    'T',
    'P',
    'E',
    'Z',
    'Y',
  ];

export default function(locale) {
  var group =
      locale.grouping === undefined || locale.thousands === undefined
        ? identity
        : formatGroup(map.call(locale.grouping, Number), locale.thousands + ''),
    currencyPrefix =
      locale.currency === undefined ? '' : locale.currency[0] + '',
    currencySuffix =
      locale.currency === undefined ? '' : locale.currency[1] + '',
    decimal = locale.decimal === undefined ? '.' : locale.decimal + '',
    numerals =
      locale.numerals === undefined
        ? identity
        : formatNumerals(map.call(locale.numerals, String)),
    percent = locale.percent === undefined ? '%' : locale.percent + '',
    minus = locale.minus === undefined ? '-' : locale.minus + '',
    nan = locale.nan === undefined ? 'NaN' : locale.nan + '';

  function newFormat(specifier) {
    specifier = formatSpecifier(specifier);

    var fill = specifier.fill,
      align = specifier.align,
      sign = specifier.sign,
      symbol = specifier.symbol,
      zero = specifier.zero,
      width = specifier.width,
      comma = specifier.comma,
      precision = specifier.precision,
      trim = specifier.trim,
      type = specifier.type;

    // The "n" type is an alias for ",g".
    if (type === 'n') (comma = true), (type = 'g');
    // The "" type, and any invalid type, is an alias for ".12~g".
    else if (!formatTypes[type])
      precision === undefined && (precision = 12), (trim = true), (type = 'g');

    // If zero fill is specified, padding goes after sign and before digits.
    if (zero || (fill === '0' && align === '='))
      (zero = true), (fill = '0'), (align = '=');

    // Compute the prefix and suffix.
    // For SI-prefix, the suffix is lazily computed.
    var prefix =
        symbol === '$'
          ? currencyPrefix
          : symbol === '#' && /[boxX]/.test(type)
          ? '0' + type.toLowerCase()
          : '',
      suffix =
        symbol === '$' ? currencySuffix : /[%p]/.test(type) ? percent : '';

    // What format function should we use?
    // Is this an integer type?
    // Can this type generate exponential notation?
    var formatType = formatTypes[type],
      maybeSuffix = /[defgprs%]/.test(type);

    // Set the default precision if not specified,
    // or clamp the specified precision to the supported range.
    // For significant precision, it must be in [1, 21].
    // For fixed precision, it must be in [0, 20].
    precision =
      precision === undefined
        ? 6
        : /[gprs]/.test(type)
        ? Math.max(1, Math.min(21, precision))
        : Math.max(0, Math.min(20, precision));

    function format(value) {
      var valuePrefix = prefix,
        valueSuffix = suffix,
        i,
        n,
        c;

      if (type === 'c') {
        valueSuffix = formatType(value) + valueSuffix;
        value = '';
      } else {
        value = +value;

        // Determine the sign. -0 is not less than 0, but 1 / -0 is!
        var valueNegative = value < 0 || 1 / value < 0;

        // Perform the initial formatting.
        value = isNaN(value) ? nan : formatType(Math.abs(value), precision);

        // Trim insignificant zeros.
        if (trim) value = formatTrim(value);

        // If a negative value rounds to zero after formatting, and no explicit positive sign is requested, hide the sign.
        if (valueNegative && +value === 0 && sign !== '+')
          valueNegative = false;

        // Compute the prefix and suffix.
        valuePrefix =
          (valueNegative
            ? sign === '('
              ? sign
              : minus
            : sign === '-' || sign === '('
            ? ''
            : sign) + valuePrefix;
        valueSuffix =
          (type === 's' ? prefixes[8 + prefixExponent / 3] : '') +
          valueSuffix +
          (valueNegative && sign === '(' ? ')' : '');

        // Break the formatted value into the integer “value” part that can be
        // grouped, and fractional or exponential “suffix” part that is not.
        if (maybeSuffix) {
          (i = -1), (n = value.length);
          while (++i < n) {
            if (((c = value.charCodeAt(i)), 48 > c || c > 57)) {
              valueSuffix =
                (c === 46 ? decimal + value.slice(i + 1) : value.slice(i)) +
                valueSuffix;
              value = value.slice(0, i);
              break;
            }
          }
        }
      }

      // If the fill character is not "0", grouping is applied before padding.
      if (comma && !zero) value = group(value, Infinity);

      // Compute the padding.
      var length = valuePrefix.length + value.length + valueSuffix.length,
        padding =
          length < width ? new Array(width - length + 1).join(fill) : '';

      // If the fill character is "0", grouping is applied after padding.
      if (comma && zero)
        (value = group(
          padding + value,
          padding.length ? width - valueSuffix.length : Infinity
        )),
          (padding = '');

      // Reconstruct the final output based on the desired alignment.
      switch (align) {
        case '<':
          value = valuePrefix + value + valueSuffix + padding;
          break;
        case '=':
          value = valuePrefix + padding + value + valueSuffix;
          break;
        case '^':
          value =
            padding.slice(0, (length = padding.length >> 1)) +
            valuePrefix +
            value +
            valueSuffix +
            padding.slice(length);
          break;
        default:
          value = padding + valuePrefix + value + valueSuffix;
          break;
      }

      return numerals(value);
    }

    format.toString = function() {
      return specifier + '';
    };

    return format;
  }

  function formatPrefix(specifier, value) {
    var f = newFormat(
        ((specifier = formatSpecifier(specifier)),
        (specifier.type = 'f'),
        specifier)
      ),
      e = Math.max(-8, Math.min(8, Math.floor(exponent(value) / 3))) * 3,
      k = Math.pow(10, -e),
      prefix = prefixes[8 + e / 3];
    return function(value) {
      return f(k * value) + prefix;
    };
  }

  return {
    format: newFormat,
    formatPrefix: formatPrefix,
  };
}
