import { ticks, tickIncrement } from 'd3-array';
import { interpolateNumber as reinterpolate } from 'd3-interpolate';
import {
  default as continuous,
  copy,
  deinterpolateLinear as deinterpolate,
} from './continuous';
import tickFormat from './tickFormat';

export function linearish(scale) {
  var domain = scale.domain;

  scale.ticks = function(count) {
    var d = domain();
    return ticks(d[0], d[d.length - 1], count == null ? 10 : count);
  };

  scale.tickFormat = function(count, specifier) {
    return tickFormat(domain(), count, specifier);
  };

  scale.nice = function(count) {
    if (count == null) count = 10;

    var d = domain(),
      i0 = 0,
      i1 = d.length - 1,
      start = d[i0],
      stop = d[i1],
      step;

    if (stop < start) {
      (step = start), (start = stop), (stop = step);
      (step = i0), (i0 = i1), (i1 = step);
    }

    step = tickIncrement(start, stop, count);

    if (step > 0) {
      start = Math.floor(start / step) * step;
      stop = Math.ceil(stop / step) * step;
      step = tickIncrement(start, stop, count);
    } else if (step < 0) {
      start = Math.ceil(start * step) / step;
      stop = Math.floor(stop * step) / step;
      step = tickIncrement(start, stop, count);
    }

    if (step > 0) {
      d[i0] = Math.floor(start / step) * step;
      d[i1] = Math.ceil(stop / step) * step;
      domain(d);
    } else if (step < 0) {
      d[i0] = Math.ceil(start * step) / step;
      d[i1] = Math.floor(stop * step) / step;
      domain(d);
    }

    return scale;
  };

  return scale;
}

export default function linear() {
  var scale = continuous(deinterpolate, reinterpolate);

  scale.copy = function() {
    return copy(scale, linear());
  };

  return linearish(scale);
}
