import none from './none';
import { sum } from './ascending';

export default function(series) {
  var n = series.length,
    i,
    j,
    sums = series.map(sum),
    order = none(series).sort(function(a, b) {
      return sums[b] - sums[a];
    }),
    top = 0,
    bottom = 0,
    tops = [],
    bottoms = [];

  for (i = 0; i < n; ++i) {
    j = order[i];
    if (top < bottom) {
      top += sums[j];
      tops.push(j);
    } else {
      bottom += sums[j];
      bottoms.push(j);
    }
  }

  return bottoms.reverse().concat(tops);
}
