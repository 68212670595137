exports = module.exports = trim;

function trim(str) {
  return str.replace(/^\s*|\s*$/g, '');
}

exports.left = function(str) {
  return str.replace(/^\s*/, '');
};

exports.right = function(str) {
  return str.replace(/\s*$/, '');
};
