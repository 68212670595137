export default function(array, f) {
  if (f == null) f = pair;
  var i = 0,
    n = array.length - 1,
    p = array[0],
    pairs = new Array(n < 0 ? 0 : n);
  while (i < n) pairs[i] = f(p, (p = array[++i]));
  return pairs;
}

export function pair(a, b) {
  return [a, b];
}
