import interval from './interval.js';

var utcYear = interval(
  function(date) {
    date.setUTCMonth(0, 1);
    date.setUTCHours(0, 0, 0, 0);
  },
  function(date, step) {
    date.setUTCFullYear(date.getUTCFullYear() + step);
  },
  function(start, end) {
    return end.getUTCFullYear() - start.getUTCFullYear();
  },
  function(date) {
    return date.getUTCFullYear();
  }
);

// An optimized implementation for this simple case.
utcYear.every = function(k) {
  return !isFinite((k = Math.floor(k))) || !(k > 0)
    ? null
    : interval(
        function(date) {
          date.setUTCFullYear(Math.floor(date.getUTCFullYear() / k) * k);
          date.setUTCMonth(0, 1);
          date.setUTCHours(0, 0, 0, 0);
        },
        function(date, step) {
          date.setUTCFullYear(date.getUTCFullYear() + step * k);
        }
      );
};

export default utcYear;
export var utcYears = utcYear.range;
