var ListCache = require('./_ListCache'),
  stackClear = require('./_stackClear'),
  stackDelete = require('./_stackDelete'),
  stackGet = require('./_stackGet'),
  stackHas = require('./_stackHas'),
  stackSet = require('./_stackSet');

/**
 * Creates a stack cache object to store key-value pairs.
 *
 * @private
 * @constructor
 * @param {Array} [entries] The key-value pairs to cache.
 */
function Stack(entries) {
  var data = (this.__data__ = new ListCache(entries));
  this.size = data.size;
}

// Add methods to `Stack`.
Stack.prototype.clear = stackClear;
Stack.prototype['delete'] = stackDelete;
Stack.prototype.get = stackGet;
Stack.prototype.has = stackHas;
Stack.prototype.set = stackSet;

module.exports = Stack;
