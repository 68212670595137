import noop from '../noop';

function LinearClosed(context) {
  this._context = context;
}

LinearClosed.prototype = {
  areaStart: noop,
  areaEnd: noop,
  lineStart: function() {
    this._point = 0;
  },
  lineEnd: function() {
    if (this._point) this._context.closePath();
  },
  point: function(x, y) {
    (x = +x), (y = +y);
    if (this._point) this._context.lineTo(x, y);
    else (this._point = 1), this._context.moveTo(x, y);
  },
};

export default function(context) {
  return new LinearClosed(context);
}
