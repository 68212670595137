import ascending from './ascending';

export default function(values, compare) {
  if (!(n = values.length)) return;
  var n,
    i = 0,
    j = 0,
    xi,
    xj = values[j];

  if (compare == null) compare = ascending;

  while (++i < n) {
    if (compare((xi = values[i]), xj) < 0 || compare(xj, xj) !== 0) {
      (xj = xi), (j = i);
    }
  }

  if (compare(xj, xj) === 0) return j;
}
