'use strict';

exports.__esModule = true;
exports.classNamesShape = exports.timeoutsShape = void 0;

var _propTypes = _interopRequireDefault(require('prop-types'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var timeoutsShape =
  process.env.NODE_ENV !== 'production'
    ? _propTypes.default.oneOfType([
        _propTypes.default.number,
        _propTypes.default.shape({
          enter: _propTypes.default.number,
          exit: _propTypes.default.number,
          appear: _propTypes.default.number,
        }).isRequired,
      ])
    : null;
exports.timeoutsShape = timeoutsShape;
var classNamesShape =
  process.env.NODE_ENV !== 'production'
    ? _propTypes.default.oneOfType([
        _propTypes.default.string,
        _propTypes.default.shape({
          enter: _propTypes.default.string,
          exit: _propTypes.default.string,
          active: _propTypes.default.string,
        }),
        _propTypes.default.shape({
          enter: _propTypes.default.string,
          enterDone: _propTypes.default.string,
          enterActive: _propTypes.default.string,
          exit: _propTypes.default.string,
          exitDone: _propTypes.default.string,
          exitActive: _propTypes.default.string,
        }),
      ])
    : null;
exports.classNamesShape = classNamesShape;
